import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { noop, remCalc } from '@lux/helpers';
import {
  Column,
  Heading,
  Label,
  Paragraph,
  Row,
  Spinner,
  Theme
} from '@lux/components-gomo';

import { TextItem, Anchor } from '../../components/tracked-components';
import { SectionDivider } from '../SectionDivider';
import { SimTypeSelector } from '../SimTypeSelector';
import { CallBarringSelection } from '../CallBarringSlection';

const StyledRow = styled(Row)`
  margin-left: -4px;
  margin-right: -4px;
  margin-top: ${Theme.spacing.small};
`;

const StyledColumn = styled(Column)`
  padding: 4px;
`;

const StyledAnchorContainer = styled.div`
  margin: ${Theme.spacing.small} 0;
`;

const StyledHeading = styled(Heading)`
  font-weight: normal;
  color: ${Theme.colours.gomo_black_33};
  margin: ${Theme.spacing.small} 0;
`;

const SpinnerContainer = styled.div`
  height: ${remCalc(200)};
`;

const NumberCollection = props => {
  const {
    selectedNumber,
    numbers,
    unavailableNumbers,
    page,
    simTypes,
    selectedSimType,
    myInfoServiceAvailable,
    loading,
    loadingText,
    onNumberClick,
    onShowMoreClick,
    onShowLessClick,
    onSimTypeSelect,
    callBarringType,
    onCallBarringTypeSelect
  } = props;

  const showMoreOrLessMap = {
    0: { clickHandler: noop, anchorText: 'more' },
    1: { clickHandler: onShowMoreClick, anchorText: 'more' },
    2: { clickHandler: onShowLessClick, anchorText: 'less' }
  };

  const anchorClickHandler = showMoreOrLessMap[page].clickHandler;
  const anchorText = showMoreOrLessMap[page].anchorText;

  return (
    <Fragment>
      {loading && (
        <SpinnerContainer>
          <Spinner
            spinnerType="hybrid"
            show
            noOverlay
            inverted
            loadingText={loadingText}
          />
        </SpinnerContainer>
      )}
      {!loading && selectedNumber && (
        <Fragment>
          <StyledHeading level={2}>{selectedNumber}</StyledHeading>
          <Paragraph>
            Awesome! This will be your brand new mobile number
          </Paragraph>
        </Fragment>
      )}
      {!loading && (
        <Fragment>
          <Label>Choose number</Label>
          <StyledRow>
            {numbers.map((number, index) => {
              const disabled = unavailableNumbers.includes(number);
              return (
                <StyledColumn
                  sm={4}
                  key={index}
                  data-testid={`testSelectNumber-${index}`}
                >
                  <TextItem
                    block
                    disabled={disabled}
                    selected={!disabled && selectedNumber === number}
                    onClick={() => onNumberClick(number)}
                  >
                    {number}
                  </TextItem>
                </StyledColumn>
              );
            })}
          </StyledRow>
          <StyledAnchorContainer>
            <Anchor onClick={anchorClickHandler}>Show {anchorText}</Anchor>
          </StyledAnchorContainer>

          <SectionDivider />

          <SimTypeSelector
            simTypes={simTypes}
            selectedSimType={selectedSimType}
            myInfoServiceAvailable={myInfoServiceAvailable}
            onSimTypeSelect={onSimTypeSelect}
          />
          <SectionDivider />
          <CallBarringSelection
            callBarringType={callBarringType}
            onCallBarringTypeSelect={onCallBarringTypeSelect}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

NumberCollection.defaultProps = {
  selectedNumber: '',
  numbers: [],
  unavailableNumbers: [],
  page: 0,
  simTypes: [],
  selectedSimType: '',
  myInfoServiceAvailable: true,
  loading: false,
  loadingText: '',
  onNumberClick: noop,
  onShowMoreClick: noop,
  onShowLessClick: noop,
  onSimTypeSelect: noop
};

NumberCollection.propTypes = {
  /** Selected Mobile number */
  selectedNumber: PropTypes.string,
  /** List of mobile numbers */
  numbers: PropTypes.arrayOf(PropTypes.string),
  /** List of unavailable mobile numbers */
  unavailableNumbers: PropTypes.arrayOf(PropTypes.string),
  /** Current page*/
  page: PropTypes.number,
  /** List of available sim types */
  simTypes: PropTypes.arrayOf(
    PropTypes.shape({
      /** Sim type value */
      simType: PropTypes.string,
      /** Sim type title */
      title: PropTypes.string,
      /** Sim type description */
      description: PropTypes.string,
      /** Sim type price */
      price: PropTypes.string
    })
  ),
  /** Selected sim type value */
  selectedSimType: PropTypes.string,
  /** Selected callbarring type value */
  callBarringType: PropTypes.string,
  /** Indicates whether my info under maintenance */
  myInfoServiceAvailable: PropTypes.bool,
  /** If numbers or sim type details are loading */
  loading: PropTypes.bool,
  /** Loading text to be displayed */
  loadingText: PropTypes.string,
  /** Event handler for clicking on a number */
  onNumberClick: PropTypes.func,
  /** Event handler for showing more numbers */
  onShowMoreClick: PropTypes.func,
  /** Event handler for showing less numbers */
  onShowLessClick: PropTypes.func,
  /** Event handler to notify when sim type is selected */
  onSimTypeSelect: PropTypes.func,
  /** Event handler to notify when callbarring type is selected */
  onCallBarringTypeSelect: PropTypes.func
};

export default NumberCollection;
