import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { navigate } from 'gatsby';
import { isValidMobileNumber, noop } from '@lux/helpers';
import { Heading, GenericModalContent } from '@lux/components-gomo';

import actions from '../../actions';
import { NUMBER_SELECTION, CTA_TEXT } from '../../constants/page_content.json';
import { checkMobileNumberOnly } from '../../helpers/mobileNumber';
import withApiFailureModal from '../withApiFailureModal';
import NumberCollection from '../../components/NumberCollection';
import PortInNumber from '../../components/PortInNumber';
import NavControls from '../../components/NavControls';
import { PAGE_RANGE } from '../../constants/app_configuration';
import { Tabs, Modal } from '../../components/tracked-components';
import {
  createLoadingSelector,
  createErrorMessageSelector,
  getNumberValidationStatus
} from '../../selectors';
import { getRedirectPathFromSelectNumberPage } from '../../helpers/routes';
import {
  GTM_DATALAYER_EVENTS,
  GTM_EVENT_ACTIONS,
  GTM_EVENT_CATEGORIES
} from '../../constants/gtmDataLayerEvents';
import { getErrorPopupDataLayerVars } from '../../helpers/datalayer';

const { TAB_ITEMS, MODAL_CONTENT } = NUMBER_SELECTION;

const loadingNumbersSelector = createLoadingSelector(['GET_NUMBERS']);
const loadingPortInOrReserveSelector = createLoadingSelector([
  'PORT_IN_NUMBER',
  'RESERVE_NUMBER'
]);
const loadingSimTypeDetailSelector = createLoadingSelector([
  'GET_SIM_TYPE_DETAILS'
]);
const loadingDelDatesSelector = createLoadingSelector(['GET_DELIVERY_DATES']);
const portInErrorSelector = createErrorMessageSelector(['PORT_IN_NUMBER']);
const reserveErrorSelector = createErrorMessageSelector(['RESERVE_NUMBER']);

export class SelectNumber extends Component {
  state = {
    selectedMode: this.props.selectedMode || 'reserveNumber',
    selectedNumber: this.props.reservedNumber || '',
    existingNumber: this.props.existingNumber || '',
    portInNumberAcknowledged: this.props.portInNumberAcknowledged || false,
    errors: { existingNumber: '' },
    captchaReady: false
  };

  componentDidMount() {
    this.resetData();
    this.injectReCaptcha();
    if (this.props.page === 0) {
      this.props.getApigeeToken(() => {
        this.props.getSimTypeDetails();

        // fetch available numbers for the first time
        const randomNo = Math.floor(Math.random() * PAGE_RANGE);
        this.props.getNumbers(randomNo);
      });
    }

    this.props.saveQueryString();
  }

  componentDidUpdate(prevProps) {
    const { page } = this.props;
    const { selectedMode } = this.state;
    if (prevProps.page < page && selectedMode === 'reserveNumber') {
      this.bottomView.scrollIntoView({ behavior: 'smooth' });
    }
  }

  componentWillUnmount() {
    this.removeReCaptcha();
  }

  resetData = () => {
    const {
      resetDeliveryDetails,
      resetUserDetails,
      resetDeliveryDates,
      retrieveAddressClear,
      resetUploadImage,
      resetSelfCollect
    } = this.props;

    resetDeliveryDetails();
    resetUserDetails();
    resetDeliveryDates();
    retrieveAddressClear();
    resetUploadImage();
    resetSelfCollect();
  };

  injectReCaptcha = () => {
    window.onRecaptchaSubmit = function(reCaptchaResponse) {
      this.submitReserveNumber(reCaptchaResponse);
    }.bind(this);

    window.onloadCallback = function() {
      this.setState({ captchaReady: true });
    }.bind(this);

    const script = document.createElement('script');

    script.async = true;
    script.defer = true;
    script.src =
      'https://www.google.com/recaptcha/api.js?onload=onloadCallback';

    if (document.head) {
      document.head.appendChild(script);
    }
  };

  async removeReCaptcha() {
    while (this.recaptchaRef.firstChild) {
      this.recaptchaRef.removeChild(this.recaptchaRef.firstChild);
    }
    if (window.grecaptcha) {
      await window.grecaptcha.reset();
      delete window['onRecaptchaSubmit'];
      delete window['onloadCallback'];
      delete window['grecaptcha'];
    }
  }

  submitReserveNumber = recaptchaReponse => {
    const {
      portInNumber,
      reserveNumber,
      unreserveNumber,
      rereserveNumber,
      lockedToken,
      reservedNumber,
      selectedSimType
    } = this.props;
    const {
      selectedNumber,
      selectedMode,
      existingNumber,
      portInNumberAcknowledged
    } = this.state;

    const isValidMobile = isValidMobileNumber(existingNumber);
    const isPortIn = selectedMode === 'portIn' && isValidMobile;
    const isReserve = selectedMode === 'reserveNumber' && selectedNumber;
    const isPortInAndUnreserve = isPortIn && lockedToken && reservedNumber;
    const isReserveAnotherNo =
      isReserve && lockedToken && reservedNumber !== selectedNumber;
    const isReserveSameNo =
      isReserve && lockedToken && reservedNumber === selectedNumber;

    if (isPortIn) {
      // if is port-in number
      portInNumber(existingNumber, portInNumberAcknowledged, selectedSimType);
    } else if (isReserveSameNo) {
      // if user is coming back from another page and reserving the same number
      navigate(getRedirectPathFromSelectNumberPage(selectedSimType));
    } else if (isReserve) {
      // if is reserving a new number
      if (isReserveAnotherNo) {
        const payload = { lockedToken, reservedNumber, selectedNumber };

        rereserveNumber(payload, recaptchaReponse);
      } else {
        reserveNumber(selectedNumber, recaptchaReponse);
      }
    }

    if (isPortInAndUnreserve) {
      unreserveNumber(lockedToken, reservedNumber, recaptchaReponse);
    }
    this.props.clearPreviewImage('front');
    this.props.clearPreviewImage('back');
  };

  handleTabChange = mode => {
    this.setState({ selectedMode: mode });
  };

  handleNextButtonClick = () => {
    window.grecaptcha.execute();
  };

  handleExistingNumberChange = e => {
    this.setState({ existingNumber: e.target.value });
  };

  handleNumberClick = number => {
    this.setState({ selectedNumber: number });
  };

  handleShowMoreClick = () => {
    this.props.setNumbersPage(2);
  };

  handleShowLessClick = () => {
    this.props.setNumbersPage(1);
  };

  handleSimTypeSelect = simType => {
    this.props.setSelectedSimType(simType);
  };

  handleCallBarringTypeSelect = cbt => {
    this.props.setCallBarringType(cbt);
  };

  handleOnBlur = () => {
    const { existingNumber } = this.state;
    let errors = { ...this.state.errors };
    errors.existingNumber = checkMobileNumberOnly(existingNumber);
    this.setState({ errors });
  };

  handlePortInNumberAcknowledgementChange = () => {
    this.setState({
      portInNumberAcknowledged: !this.state.portInNumberAcknowledged
    });
  };

  handleModalClose = () => {
    if (window.grecaptcha) {
      window.grecaptcha.reset();
    }

    if (this.props.portInNumberError) {
      this.props.clearError('PORT_IN_NUMBER');
      if (
        this.props.portInNumberError === '98' ||
        this.props.portInNumberError === '99'
      ) {
        this.setState({
          selectedNumber: '',
          selectedMode: 'reserveNumber',
          existingNumber: ''
        });
      }
    } else if (this.props.reserveNumberError) {
      this.setState({ selectedNumber: '' });
      this.props.clearError('RESERVE_NUMBER');
    }
  };

  shouldDisableNextButton = () => {
    const {
      selectedMode,
      selectedNumber,
      existingNumber,
      portInNumberAcknowledged
    } = this.state;
    const {
      selectedSimType,
      loadingSimTypeDetails,
      loadingPortInOrReserve,
      loadingDelDates,
      callBarringType
    } = this.props;

    let shouldDisableNextButton = false;
    if (selectedMode === 'reserveNumber') {
      shouldDisableNextButton =
        !selectedNumber ||
        !selectedSimType ||
        loadingSimTypeDetails ||
        loadingPortInOrReserve ||
        loadingDelDates ||
        !callBarringType;
    } else if (selectedMode === 'portIn') {
      shouldDisableNextButton =
        !isValidMobileNumber(existingNumber) ||
        !portInNumberAcknowledged ||
        !selectedSimType ||
        loadingSimTypeDetails ||
        loadingPortInOrReserve ||
        loadingDelDates ||
        !callBarringType;
    }

    return shouldDisableNextButton;
  };

  renderSelectNumberContent = () => {
    const {
      selectedMode,
      selectedNumber,
      existingNumber,
      portInNumberAcknowledged,
      errors
    } = this.state;

    const {
      loadingNumbers,
      loadingSimTypeDetails,
      numbers,
      unavailableNumbers,
      page,
      selectedSimType,
      callBarringType
    } = this.props;

    const { simTypes, myInfoServiceAvailable } = this.props;

    if (selectedMode === 'portIn') {
      const errorMessage = errors['existingNumber'];

      return (
        <PortInNumber
          errorMessage={errorMessage}
          number={existingNumber}
          numberAcknowledged={portInNumberAcknowledged}
          simTypes={simTypes}
          selectedSimType={selectedSimType}
          myInfoServiceAvailable={myInfoServiceAvailable}
          loading={loadingSimTypeDetails}
          onNumberChange={this.handleExistingNumberChange}
          onNumberBlur={this.handleOnBlur}
          onNumberAcknowledgementChange={
            this.handlePortInNumberAcknowledgementChange
          }
          onSimTypeSelect={this.handleSimTypeSelect}
          callBarringType={callBarringType}
          onCallBarringTypeSelect={this.handleCallBarringTypeSelect}
        />
      );
    } else if (selectedMode === 'reserveNumber') {
      const numbersToRender = numbers.slice(0, page * 9);

      let loadingText = '';
      const loading = loadingNumbers || loadingSimTypeDetails;
      if (loading) {
        loadingText = 'Hang tight, loading...';
      }

      return (
        <>
          <NumberCollection
            selectedNumber={selectedNumber}
            numbers={numbersToRender}
            unavailableNumbers={unavailableNumbers}
            page={page}
            simTypes={simTypes}
            selectedSimType={selectedSimType}
            myInfoServiceAvailable={myInfoServiceAvailable}
            loading={loading}
            loadingText={loadingText}
            onNumberClick={this.handleNumberClick}
            onShowMoreClick={this.handleShowMoreClick}
            onShowLessClick={this.handleShowLessClick}
            onSimTypeSelect={this.handleSimTypeSelect}
            callBarringType={callBarringType}
            onCallBarringTypeSelect={this.handleCallBarringTypeSelect}
          />
        </>
      );
    }
  };

  handleCloseNumberValidationModal = () => {
    const { resetNumberValidationStatus } = this.props;
    window.grecaptcha && window.grecaptcha.reset();
    resetNumberValidationStatus();
  };

  renderModal = (props, display) => {
    const errorModalDataLayerVars = getErrorPopupDataLayerVars({
      event: GTM_DATALAYER_EVENTS.ORDER_INITIATE,
      errorName: props.paragraphText,
      eventLabel: props.buttonText,
      networkType: this.state.selectedMode
    });

    return display ? (
      <Modal
        onModalClose={noop}
        onModalRender={noop}
        renderProp={() => <GenericModalContent {...props} />}
        trackEvent={{
          modalRender: errorModalDataLayerVars.render,
          modalClose: errorModalDataLayerVars.buttonClick
        }}
      />
    ) : null;
  };

  render() {
    const { selectedMode, captchaReady } = this.state;
    const {
      portInNumberError,
      reserveNumberError,
      numberValidationStatus
    } = this.props;

    const modalContent = MODAL_CONTENT[this.state.selectedMode];
    const {
      headerText,
      paragraphText,
      buttonText,
      anchorText,
      anchorUrl,
      numberToReplace
    } = modalContent;
    const numberValidationModalData = MODAL_CONTENT['numberValidationFailed'];
    // replace number in modal with existingNumber or selectedNumber
    const textToReplace = this.state[numberToReplace];
    const replacedParaText =
      selectedMode === 'reserveNumber'
        ? paragraphText.replace(':number', textToReplace)
        : '';

    const unableToTransferErrorContent = MODAL_CONTENT.portIn.unableToTransfer;

    const reserveNumberErrorDataLayerVars = getErrorPopupDataLayerVars({
      event: GTM_DATALAYER_EVENTS.ORDER_INITIATE,
      errorName: replacedParaText,
      eventLabel: buttonText,
      networkType: this.state.selectedMode
    });

    const portInNumberErrorDataLayerVars = getErrorPopupDataLayerVars({
      event: GTM_DATALAYER_EVENTS.ORDER_INITIATE,
      errorName: modalContent[portInNumberError]
        ? modalContent[portInNumberError].paragraphText
        : modalContent.default?.paragraphText,
      eventLabel: modalContent[portInNumberError]
        ? modalContent[portInNumberError].buttonText
        : modalContent.default?.buttonText,
      networkType: this.state.selectedMode
    });

    const unableToTransferErrorDataLayerVars = getErrorPopupDataLayerVars({
      event: GTM_DATALAYER_EVENTS.ORDER_INITIATE,
      errorName: unableToTransferErrorContent.paragraphText,
      eventLabel: CTA_TEXT.OK_GOT_IT,
      networkType: this.state.selectedMode
    });

    return (
      <Fragment>
        <div ref={e => (this.topView = e)} />
        <Heading level={4} secondary>
          Hello, get GOMO in a jiffy.
        </Heading>
        <Heading level={2}>Get started with your GOMO mobile line</Heading>
        <Tabs
          items={TAB_ITEMS}
          selectedValue={selectedMode}
          onChange={this.handleTabChange}
        />
        {this.renderSelectNumberContent()}
        <NavControls
          disableNextButton={!captchaReady || this.shouldDisableNextButton()}
          onNextButtonClick={this.handleNextButtonClick}
          trackEventNextButton={{
            click: {
              event: GTM_DATALAYER_EVENTS.ORDER_INITIATE,
              networkType: this.state.selectedMode,
              eventCategory: GTM_EVENT_CATEGORIES.CONTENT_CLICK,
              eventAction: GTM_EVENT_ACTIONS.BUTTON_CLICK,
              eventLabel: CTA_TEXT.NEXT
            }
          }}
        />
        <div
          ref={ref => (this.recaptchaRef = ref)}
          className="g-recaptcha"
          data-sitekey={
            process.env.NODE_ENV === 'test'
              ? '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
              : process.env.GATSBY_RECAPTCHA_SITE_KEY
          }
          data-callback="onRecaptchaSubmit"
          data-size="invisible"
        />
        <div ref={el => (this.bottomView = el)} />
        {reserveNumberError && (
          <Modal
            onModalClose={this.handleModalClose}
            onModalRender={noop}
            renderProp={({ onModalClose }) => (
              <GenericModalContent
                theme="hybrid"
                headerText={headerText}
                paragraphText={replacedParaText}
                buttonText={buttonText}
                anchorText={anchorText}
                anchorUrl={anchorUrl}
                onModalClose={onModalClose}
              />
            )}
            trackEvent={{
              modalRender: reserveNumberErrorDataLayerVars.render,
              modalClose: reserveNumberErrorDataLayerVars.buttonClick
            }}
          />
        )}
        {portInNumberError && portInNumberError !== '05' && (
          <Modal
            onModalClose={this.handleModalClose}
            onModalRender={noop}
            renderProp={({ onModalClose }) => (
              <GenericModalContent
                theme="hybrid"
                headerText={
                  modalContent[portInNumberError]
                    ? modalContent[portInNumberError].headerText
                    : modalContent.default.headerText
                }
                paragraphText={
                  modalContent[portInNumberError]
                    ? modalContent[portInNumberError].paragraphText
                    : modalContent.default.paragraphText
                }
                buttonText={
                  modalContent[portInNumberError]
                    ? modalContent[portInNumberError].buttonText
                    : modalContent.default.buttonText
                }
                anchorText={anchorText}
                anchorUrl={anchorUrl}
                onModalClose={onModalClose}
              />
            )}
            trackEvent={{
              modalRender: portInNumberErrorDataLayerVars.render,
              modalClose: portInNumberErrorDataLayerVars.buttonClick
            }}
          />
        )}
        {portInNumberError === '05' && (
          <Modal
            onModalClose={this.handleModalClose}
            onModalRender={noop}
            renderProp={({ onModalClose }) => (
              <GenericModalContent
                theme="hybrid"
                headerText={unableToTransferErrorContent.headerText}
                paragraphText={unableToTransferErrorContent.paragraphText}
                buttonText={CTA_TEXT.OK_GOT_IT}
                onModalClose={onModalClose}
              />
            )}
            trackEvent={{
              modalRender: unableToTransferErrorDataLayerVars.render,
              modalClose: unableToTransferErrorDataLayerVars.buttonClick
            }}
          />
        )}

        {this.renderModal(
          {
            theme: numberValidationModalData.theme,
            headerText: numberValidationModalData.headerText,
            buttonText: numberValidationModalData.buttonText,
            paragraphText: numberValidationModalData.paragraphTextPlaceholder,
            onModalClose: this.handleCloseNumberValidationModal
          },
          numberValidationStatus
        )}
      </Fragment>
    );
  }
}

SelectNumber.defaultProps = {
  resetDeliveryDetails: () => null,
  resetUserDetails: () => null,
  resetDeliveryDates: () => null,
  retrieveAddressClear: () => null,
  resetUploadImage: () => null,
  resetSelfCollect: () => null,
  saveQueryString: () => {}
};

const mapStateToProps = state => {
  const { availableNumbers, selectedNumber, simTypeDetails } = state;

  return {
    numbers: availableNumbers.data,
    unavailableNumbers: availableNumbers.unavailableNumbers,
    page: availableNumbers.page,
    simTypes: simTypeDetails.simTypes,
    selectedSimType: simTypeDetails.selectedSimType,
    myInfoServiceAvailable: simTypeDetails.myInfoServiceAvailable,
    loadingNumbers: loadingNumbersSelector(state),
    loadingPortInOrReserve: loadingPortInOrReserveSelector(state),
    loadingSimTypeDetails: loadingSimTypeDetailSelector(state),
    loadingDelDates: loadingDelDatesSelector(state),
    portInNumberError: portInErrorSelector(state),
    reserveNumberError: reserveErrorSelector(state),
    selectedMode: selectedNumber.mode,
    lockedToken: selectedNumber.lockedToken,
    reservedNumber:
      selectedNumber.mode === 'reserveNumber' ? selectedNumber.number : '',
    existingNumber:
      selectedNumber.mode === 'portIn' ? selectedNumber.number : '',
    portInNumberAcknowledged:
      selectedNumber.mode === 'portIn'
        ? selectedNumber.portInNumberAcknowledged
        : false,
    numberValidationStatus: getNumberValidationStatus(state),
    callBarringType: selectedNumber.callBarringType
  };
};

/* istanbul ignore next */
const mapDispatchToProps = dispatch => {
  const {
    getNumbers,
    setNumbersPage,
    reserveNumber,
    unreserveNumber,
    rereserveNumber,
    portInNumber,
    getSimTypeDetails,
    setSelectedSimType,
    clearError,
    clearPreviewImage,
    validateNumberResponse,
    pushEventToDataLayer,
    setCallBarringType
  } = actions;

  return {
    getNumbers: page => dispatch(getNumbers(page)),
    setNumbersPage: page => dispatch(setNumbersPage(page)),
    reserveNumber: (number, recaptchaResponse) =>
      dispatch(reserveNumber(number, recaptchaResponse)),
    unreserveNumber: (token, number, recaptchaReponse) =>
      dispatch(unreserveNumber(token, number, recaptchaReponse)),
    rereserveNumber: (payload, recaptchaReponse) =>
      dispatch(rereserveNumber(payload, recaptchaReponse)),
    portInNumber: (number, portInNumberAcknowledged, selectedSimType) =>
      dispatch(portInNumber(number, portInNumberAcknowledged, selectedSimType)),
    getSimTypeDetails: () => dispatch(getSimTypeDetails()),
    setSelectedSimType: simType => dispatch(setSelectedSimType(simType)),
    clearError: errorKey => dispatch(clearError(errorKey)),
    clearPreviewImage: key => dispatch(clearPreviewImage(key)),
    resetNumberValidationStatus: () => dispatch(validateNumberResponse(false)),
    resetDeliveryDetails: () => dispatch(actions.resetDeliveryDetails()),
    resetUserDetails: () => dispatch(actions.resetUserDetails()),
    resetDeliveryDates: () => dispatch(actions.resetDeliveryDates()),
    retrieveAddressClear: () => dispatch(actions.retrieveAddressClear()),
    resetUploadImage: () => dispatch(actions.resetUploadImage()),
    resetSelfCollect: () => dispatch(actions.resetSelfCollect()),
    saveQueryString: () => dispatch(actions.saveQueryString()),
    getApigeeToken: successCallback =>
      dispatch(actions.getApigeeToken(successCallback)),
    pushEventToDataLayer: data => dispatch(pushEventToDataLayer(data)),
    setCallBarringType: cbt => dispatch(setCallBarringType(cbt))
  };
};

const connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectNumber);

export default withApiFailureModal(connected);
