import React from 'react';
import PropTypes from 'prop-types';
import { Column, Paragraph, Anchor } from '@lux/components-gomo';
import {
  StyledContainer,
  StyledHeading,
  StyledImg,
  StyledCtaContainer,
  StyledButton
} from './styles';
import { noop } from '@lux/helpers';
import { RetrieveMyInfoButton } from '../common';

export const EmphasizedInfo = ({
  headingImage,
  heading,
  description,
  buttonText,
  buttonProps,
  ImageButton,
  linkText,
  highlightStyle,
  onButtonClick,
  onLinkClick
}) => {
  return (
    <StyledContainer highlightStyle={highlightStyle}>
      <Column>
        {headingImage && (
          <StyledImg data-testid="heading-image" src={headingImage} />
        )}
        <StyledHeading data-testid="heading-text">{heading}</StyledHeading>
        <Paragraph data-testid="description-text">{description}</Paragraph>
        {buttonText && (
          <StyledCtaContainer>
            <StyledButton onClick={onButtonClick} {...buttonProps}>
              {buttonText}
            </StyledButton>
          </StyledCtaContainer>
        )}
        {ImageButton && (
          <StyledCtaContainer>
            <RetrieveMyInfoButton onClick={onButtonClick} {...buttonProps} />
          </StyledCtaContainer>
        )}
        {linkText && (
          <StyledCtaContainer>
            <Anchor onClick={onLinkClick}>{linkText}</Anchor>
          </StyledCtaContainer>
        )}
      </Column>
    </StyledContainer>
  );
};

EmphasizedInfo.defaultProps = {
  headingImage: '',
  heading: '',
  description: '',
  buttonText: '',
  buttonProps: {},
  ImageButton: null,
  linkText: '',
  highlightStyle: 'primary',
  onButtonClick: noop,
  onLinkClick: noop
};

EmphasizedInfo.propTypes = {
  /** Heading image url */
  headingImage: PropTypes.string,
  /** Heading text */
  heading: PropTypes.string,
  /** Description */
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Button text */
  buttonText: PropTypes.string,
  /** Extra props to be passed to button */
  buttonProps: PropTypes.object,
  /** Image button url */
  imageButton: PropTypes.string,
  /** Link text */
  linkText: PropTypes.string,
  /** Define the highlight color of the container */
  highlightStyle: PropTypes.oneOf(['primary', 'secondary']),
  /** Button click handler */
  onButtonClick: PropTypes.func,
  /** Image button click handler */
  onImageButtonClick: PropTypes.func,
  /** Link click handler */
  onLinkClick: PropTypes.func
};
