import React from 'react';
import { Label, Paragraph, Dropdown } from '@lux/components-gomo';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { EmphasizedInfo } from '../EmphasizedInfo';
import { FormItem } from '../common';
import CALL_BARRING_OPTIONS from '../../constants/call_barring_titles.json';

const StyledAcknowledgementParagraph = styled(Paragraph)`
  padding-top: 16px;
  margin-left: 4px;
`;

export const CallBarringBanner = styled.div`
  margin: 4px 4px;
  padding-bottom: 24px;
`;

export const CallBarringBannerNotice = styled(Paragraph)`
  margin: 0px 4px;
  padding-bottom: 24px;
  font-size: 12px !important;
  font-weight: 400;
  line-height: 18px;
  color: #757575;
`;

const DropdownWrapper = styled(Paragraph)`
  margin-top: 16px;
  margin-left: 4px;
`;

export const CallBarringSelection = ({
  callBarringType,
  onCallBarringTypeSelect
}) => {
  return (
    <>
      <Label>Barring Service (International Incoming Voice & SMS)</Label>
      <StyledAcknowledgementParagraph>
        Block all international incoming calls and SMSes to prevent potential
        scams.
      </StyledAcknowledgementParagraph>

      <DropdownWrapper>
        <FormItem>
          <Dropdown
            id="call-barring-type"
            placeholder="Select one (free service)"
            selectedValue={callBarringType}
            options={CALL_BARRING_OPTIONS}
            onChange={e => {
              onCallBarringTypeSelect(e.target.value);
            }}
          />
        </FormItem>
      </DropdownWrapper>

      {callBarringType && callBarringType !== 'none' && (
        <CallBarringBannerNotice>
          Service will start within 24 hours of your mobile line activation.
          You'll get an SMS once that happens.
        </CallBarringBannerNotice>
      )}

      {callBarringType && callBarringType === 'none' && (
        <CallBarringBanner>
          <EmphasizedInfo
            heading={'Will you reconsider?'}
            description={
              <Paragraph>
                By proceeding, you may be exposed to phishing attemps via calls
                and SMSes from scammers overseas.
              </Paragraph>
            }
            highlightStyle={'primary'}
          />
        </CallBarringBanner>
      )}
    </>
  );
};

CallBarringSelection.propTypes = {
  /** Selected sim type value */
  callBarringType: PropTypes.string,
  /** Event handler to notify when sim type is selected */
  onCallBarringTypeSelect: PropTypes.func
};
