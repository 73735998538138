import styled from 'styled-components';
import { Row, Paragraph, Theme } from '@lux/components-gomo';
import { remCalc } from '@lux/helpers';
import { Button } from '../tracked-components';

export const StyledContainer = styled(Row)`
  border-radius: 8px;
  background-color: ${p =>
    p.highlightStyle === 'primary'
      ? Theme.colours.gomo_light_blue
      : Theme.colours.gomo_grey_f3};
  padding: 24px;
`;

export const StyledHeading = styled(Paragraph)`
  font-weight: ${Theme.fonts.weight.semiBold};
  margin: 0;
`;

export const StyledImg = styled.img`
  width: ${remCalc(100)};
  padding-bottom: 16px;
`;

export const StyledCtaContainer = styled.div`
  padding-top: 24px;
  width: 100%;

  ${Theme.media.md`
    width: 320px;
  `}
`;

export const StyledButton = styled(Button)`
  padding-top: 24px;
`;
