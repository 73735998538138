import styled from 'styled-components';
import { Row, Column, Theme } from '@lux/components-gomo';

export const StyledRow = styled(Row)`
  margin: 8px -4px 0;
`;

export const StyledColumn = styled(Column)`
  padding: 8px;
`;

export const StyledEsimInfoTextRow = styled.div`
  margin: 8px 4px;
`;

export const StyledSimTypeContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledSimTypeTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
`;

export const StyledSimTypeTitle = styled.p`
  font-size: ${Theme.fonts.sizes.regular};
  font-weight: ${Theme.fonts.weight.regular};
`;

export const StyledSimTypeDescription = styled.p`
  text-align: left;
  padding: 0px 16px;
  font-size: ${Theme.fonts.sizes.xsmall};
  font-weight: ${Theme.fonts.weight.regular};
`;

export const StyledSimTypeInfoLinerContainer = styled(Row)`
  margin: 0 -4px;
`;
