import React from 'react';
import { Label, Paragraph, Anchor } from '@lux/components-gomo';
import PropTypes from 'prop-types';
import {
  StyledColumn,
  StyledEsimInfoTextRow,
  StyledRow,
  StyledSimTypeContentContainer,
  StyledSimTypeDescription,
  StyledSimTypeInfoLinerContainer,
  StyledSimTypeTitle,
  StyledSimTypeTitleContainer
} from './styles';
import { TextItem } from '../tracked-components';
import { ESIM_COMPATIBLE_DEVICES } from '../../constants/links.json';
import { Column } from '@dls/web';
import { isEsim } from '../../helpers/userDetailsForm';
import { EmphasizedInfo } from '../EmphasizedInfo';

export const SimTypeSelector = ({
  simTypes,
  selectedSimType,
  myInfoServiceAvailable,
  onSimTypeSelect
}) => {
  return (
    <>
      <Label>Choose SIM Type</Label>

      <StyledRow>
        {simTypes?.map(({ simType, title, price, description }) => {
          return (
            <StyledColumn key={simType} sm={12}>
              <TextItem
                id={simType}
                block
                key={simType}
                disabled={!myInfoServiceAvailable && isEsim(simType)}
                selected={simType === selectedSimType}
                onClick={() => onSimTypeSelect(simType)}
              >
                <StyledSimTypeContentContainer
                  data-testid={`sim-type-${simType}`}
                >
                  <StyledSimTypeTitleContainer>
                    <StyledSimTypeTitle>{title}</StyledSimTypeTitle>
                    <StyledSimTypeTitle>{price}</StyledSimTypeTitle>
                  </StyledSimTypeTitleContainer>
                  <StyledSimTypeDescription>
                    {description}
                  </StyledSimTypeDescription>
                </StyledSimTypeContentContainer>
              </TextItem>
            </StyledColumn>
          );
        })}
      </StyledRow>
      {!myInfoServiceAvailable && (
        <StyledSimTypeInfoLinerContainer>
          <Column>
            <Paragraph>
              Oh no, eSIM is currently unavailable. We're working hard to get it
              back up. Get a physical SIM instead.
            </Paragraph>
          </Column>
        </StyledSimTypeInfoLinerContainer>
      )}
      {isEsim(selectedSimType) && (
        <StyledEsimInfoTextRow>
          <EmphasizedInfo
            heading={'Getting an eSIM?'}
            description={
              <Paragraph>
                Make sure you have an{' '}
                <Anchor target="_blank" href={ESIM_COMPATIBLE_DEVICES.ABSOLUTE}>
                  eSIM-compatible device
                </Anchor>
                .
              </Paragraph>
            }
            highlightStyle={'primary'}
          />
        </StyledEsimInfoTextRow>
      )}
    </>
  );
};

SimTypeSelector.propTypes = {
  /** List of available sim types */
  simTypes: PropTypes.arrayOf(
    PropTypes.shape({
      /** Sim type value */
      simType: PropTypes.string,
      /** Sim type title */
      title: PropTypes.string,
      /** Sim type description */
      description: PropTypes.string,
      /** Sim type price */
      price: PropTypes.string
    })
  ),
  /** Selected sim type value */
  selectedSimType: PropTypes.string,
  /** Indicates whether my info under maintenance */
  myInfoServiceAvailable: PropTypes.bool,
  /** Event handler to notify when sim type is selected */
  onSimTypeSelect: PropTypes.func
};
