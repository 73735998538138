import React from 'react';
import styled from 'styled-components';
import { FormItem as GomoFormItem } from '@lux/components-gomo';
import retrieveMyInfoImg from '../assets/images/retrieve-my-info.png';
import { Button } from './tracked-components';

const GomoFormItemContainer = styled.div`
  padding-bottom: 8px;
`;

export const FormItem = props => (
  <GomoFormItemContainer {...props}>
    <GomoFormItem {...props} />
  </GomoFormItemContainer>
);

const StyledRetrieveMyInfoButtonContainer = styled.div`
  &&& {
    button {
      background: #f5333d url(${retrieveMyInfoImg}) no-repeat;
      background-size: 265px;
      border: 0 none #f5333d;
      border-radius: 8px;
      width: 265px;
      padding: 0;

      :hover {
        background-color: #f5333d;
        border-color: #f5333d;
      }
    }
  }
`;

export const RetrieveMyInfoButton = props => (
  <StyledRetrieveMyInfoButtonContainer>
    <Button {...props} />
  </StyledRetrieveMyInfoButtonContainer>
);
