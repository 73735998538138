import React from 'react';
import styled from 'styled-components';
import { CheckBox, Paragraph, Theme } from '@lux/components-gomo';
import { remCalc } from '@lux/helpers';

const StyledCheckBoxRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: ${remCalc(16)};
  p {
    color: ${p =>
      p.disabled ? Theme.colours.gomo_grey_be : Theme.colours.gomo_black_33};
  }
`;

const StyledCheckBoxContainer = styled.div`
  margin-right: ${Theme.spacing.xsmall};
`;

const CheckBoxRow = props => {
  const { description, onCheckBoxChange, checked, disabled } = props;
  return (
    <StyledCheckBoxRow disabled={disabled}>
      <StyledCheckBoxContainer data-testid="testCheckBox">
        <CheckBox
          disabled={disabled}
          checked={checked}
          onChange={onCheckBoxChange}
        />
      </StyledCheckBoxContainer>
      <Paragraph>{description}</Paragraph>
    </StyledCheckBoxRow>
  );
};

export default CheckBoxRow;
