import { isValidMobileLandNumber, isValidMobileNumber } from '@lux/helpers';

const SG_MOBILE_DIGITS = 8;

/** Check both mobile and landline */
export const checkMobileLandNumber = (mobile, errorText) => {
  let error = '';
  if (!mobile || mobile.trim() === '') {
    error = errorText || 'Please enter your contact number.';
  } else if (
    mobile.length !== SG_MOBILE_DIGITS ||
    !isValidMobileLandNumber(mobile)
  ) {
    error = errorText || 'Please enter a valid contact number.';
  }
  return error;
};

/** Mobile only */
export const checkMobileNumberOnly = (mobile, errorText) => {
  let error = '';
  if (!mobile || mobile.trim() === '') {
    error = errorText || 'Please enter your mobile number.';
  } else if (
    mobile.length !== SG_MOBILE_DIGITS ||
    !isValidMobileNumber(mobile)
  ) {
    error = errorText || 'Please enter a valid mobile number.';
  }
  return error;
};

export const formatMobileNumber = number => {
  return `${number.substring(0, 4)} ${number.substring(4, 8)}`;
};

/** Call barring array generator for service */
export const getCallbarringTypes = cbt => {
  if (!cbt) {
    return [];
  } else if (cbt === 's') {
    return ['SMS'];
  } else if (cbt === 'c') {
    return ['CALL'];
  } else if (cbt === 'cs') {
    return ['SMS', 'CALL'];
  } else {
    return [];
  }
};
