import { Divider } from '@dls/web';
import React from 'react';
import styled from 'styled-components';

const DividerContainer = styled.div`
  margin: ${p => `${p.top}px 0 ${p.bottom}px`};
`;

export const SectionDivider = ({ top = 32, bottom = 32 }) => {
  return (
    <DividerContainer top={top} bottom={bottom}>
      <Divider />
    </DividerContainer>
  );
};
