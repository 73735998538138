import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { GenericModalContent } from '@lux/components-gomo';
import actions from '../../actions';

import { createErrorMessageSelector } from '../../selectors';
import { GTM_DATALAYER_EVENTS } from '../../constants/gtmDataLayerEvents';
import { CTA_TEXT, GENERIC_API_ERROR } from '../../constants/page_content.json';
import { THEME } from '../../constants/app_configuration';
import { noop } from '@lux/helpers';
import { getErrorPopupDataLayerVars } from '../../helpers/datalayer';
import { Modal } from '../../components/tracked-components';

const apiErrorSelector = createErrorMessageSelector(['API']);

export const mapStateToProps = state => {
  return { apiFailure: apiErrorSelector(state) };
};

export const mapDispatchToProps = dispatch => {
  return {
    clearError: errorKey => dispatch(actions.clearError(errorKey)),
    pushEventToDataLayer: payload =>
      dispatch(actions.pushEventToDataLayer(payload))
  };
};

export class ApiFailureModal extends Component {
  handleModalClose = () => {
    if (window.grecaptcha) {
      window.grecaptcha.reset();
    }

    this.props.clearError('API');
  };

  render() {
    if (this.props.apiFailure) {
      const errorModalDataLayerVars = getErrorPopupDataLayerVars({
        event: GTM_DATALAYER_EVENTS.ORDER_INITIATE,
        errorName: GENERIC_API_ERROR.paragraphText,
        eventLabel: CTA_TEXT.OK_GOT_IT
      });

      return (
        <Modal
          onModalRender={noop}
          onModalClose={this.handleModalClose}
          renderProp={({ onModalClose }) => (
            <GenericModalContent
              theme={THEME}
              headerText={GENERIC_API_ERROR.headerText}
              paragraphText={GENERIC_API_ERROR.paragraphText}
              buttonText={CTA_TEXT.OK_GOT_IT}
              onModalClose={onModalClose}
            />
          )}
          trackEvent={{
            modalRender: errorModalDataLayerVars.render,
            modalClose: errorModalDataLayerVars.buttonClick
          }}
        />
      );
    }
    return null;
  }
}

const ConnectedModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(ApiFailureModal);

const withApiFailureModal = WrappedComponent => {
  return class extends Component {
    render() {
      return (
        <Fragment>
          <WrappedComponent {...this.props} />
          <ConnectedModal />
        </Fragment>
      );
    }
  };
};

export default withApiFailureModal;
