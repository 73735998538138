import React from 'react';
import { Helmet } from 'react-helmet';
import SelectNumber from '../containers/SelectNumber';
import SEO from '../components/SEO';
import withLayout from '../components/withLayout';
import { ThemeProvider } from '@dls/web';

import { PRODUCT_NAME, NUMBER_SELECTION } from '../constants/page_content.json';
import { METATAGS } from '../constants/seo_metatags';

const SelectNumberPage = () => {
  return (
    <ThemeProvider brand="advance">
      <Helmet defaultTitle={PRODUCT_NAME} titleTemplate="%s">
        <title>{NUMBER_SELECTION.TITLE}</title>
      </Helmet>
      <SEO
        title={METATAGS.TITLE}
        description={METATAGS.DESCRIPTION}
        keywords={METATAGS.KEYWORDS}
      />
      <SelectNumber />
    </ThemeProvider>
  );
};

export default withLayout(SelectNumberPage);
