import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { noop, remCalc } from '@lux/helpers';
import {
  Paragraph,
  Spinner,
  TextField,
  Anchor,
  Theme
} from '@lux/components-gomo';
import { FormItem } from '../common';
import CheckBoxRow from '../CheckBoxRow';
import { PORT_IN_FAQ_LINK } from '../../constants/links.json';
import { SectionDivider } from '../SectionDivider';
import { SimTypeSelector } from '../SimTypeSelector';
import { CallBarringSelection } from '../CallBarringSlection';

const StyledAcknowledgementParagraph = styled(Paragraph)`
  padding-top: ${Theme.spacing.xsmall};
`;

const SpinnerContainer = styled.div`
  height: ${remCalc(150)};
`;

const PortInNumber = props => {
  const {
    errorMessage,
    number,
    numberAcknowledged,
    simTypes,
    selectedSimType,
    myInfoServiceAvailable,
    loading,
    onNumberChange,
    onNumberBlur,
    onNumberAcknowledgementChange,
    onSimTypeSelect,
    callBarringType,
    onCallBarringTypeSelect
  } = props;

  const renderNumberAcknowledgementCheckboxDescription = () => {
    return (
      <div data-testid="number-acknowledgment-checkbox-description">
        This number is registered under my NRIC/FIN.{' '}
        <Anchor target="_blank" href={PORT_IN_FAQ_LINK.ABSOLUTE}>
          Learn more
        </Anchor>
      </div>
    );
  };

  return (
    <Fragment>
      <FormItem
        label="Postpaid mobile number"
        errorMessage={errorMessage}
        data-testid="port-in-number-input"
      >
        <TextField
          type="tel"
          placeholder="Eg. 8123 4567"
          value={number}
          onChange={onNumberChange}
          onBlur={onNumberBlur}
          maxLength={8}
        />
      </FormItem>
      <CheckBoxRow
        checked={numberAcknowledged}
        onCheckBoxChange={onNumberAcknowledgementChange}
        description={renderNumberAcknowledgementCheckboxDescription()}
      />
      <StyledAcknowledgementParagraph>
        Ensure that your current contract for mobile plan or add-ons has already
        ended to avoid any early termination charges.
      </StyledAcknowledgementParagraph>

      <SectionDivider />

      {loading ? (
        <SpinnerContainer>
          <Spinner
            spinnerType="hybrid"
            show
            noOverlay
            inverted
            loadingText={'Loading...'}
          />
        </SpinnerContainer>
      ) : (
        <>
          <SimTypeSelector
            simTypes={simTypes}
            selectedSimType={selectedSimType}
            myInfoServiceAvailable={myInfoServiceAvailable}
            onSimTypeSelect={onSimTypeSelect}
          />
          <SectionDivider />
          <CallBarringSelection
            callBarringType={callBarringType}
            onCallBarringTypeSelect={onCallBarringTypeSelect}
          />
        </>
      )}
    </Fragment>
  );
};

PortInNumber.defaultProps = {
  errorMessage: '',
  number: '',
  onNumberChange: noop,
  onNumberBlur: noop
};

PortInNumber.propTypes = {
  /** Error message */
  errorMessage: PropTypes.string,
  /** Mobile number entered */
  number: PropTypes.string,
  /** List of available sim types */
  simTypes: PropTypes.arrayOf(
    PropTypes.shape({
      /** Sim type value */
      simType: PropTypes.string,
      /** Sim type title */
      title: PropTypes.string,
      /** Sim type description */
      description: PropTypes.string,
      /** Sim type price */
      price: PropTypes.string
    })
  ),
  /** Selected sim type value */
  selectedSimType: PropTypes.string,
  /** Selected callbarring type value */
  callBarringType: PropTypes.string,
  /** Indicates whether my info under maintenance */
  myInfoServiceAvailable: PropTypes.bool,
  /** Indicates if sim type details are loading */
  loading: PropTypes.bool,
  /** Event handler for changing number */
  onNumberChange: PropTypes.func,
  /** Event handler for blurring number*/
  onNumberBlur: PropTypes.func,
  /** Event handler to notify when sim type is selected */
  onSimTypeSelect: PropTypes.func,
  /** Event handler to notify when callbarring type is selected */
  onCallBarringTypeSelect: PropTypes.func
};

export default PortInNumber;
